<script lang="ts">
    import { getContext, onDestroy, onMount } from "svelte";
    import type { Writable } from "svelte/store";

    import type { State } from "../types/state";
    let state: Writable<State> = getContext("state");

    import VchControlBar from "./controlBar/VchControlBar.svelte";
    import VchClipControls from "./controlBar/VchClipControls.svelte";
    import VchSlider from "./controlBar/VchSlider.svelte";
    import VchOverlay from "./overlays/VchOverlay.svelte";
    import VchInfoBox from "./overlays/infoBox/VchInfoBox.svelte";
    import VchLoaderOverlay from "./overlays/VchLoaderOverlay.svelte";
    import VchSettingsOverlay from "./overlays/settingsOverlay/VchSettingsOverlay.svelte";
    import { watcher } from "../utils/watcher";
    import {
        toggleFullscreen,
        isFullscreen,
    } from "../../../../modules/vu-core/dom/fullscreen";
    import { togglePictureInPicture } from "../../../../modules/vu-core/dom/pip";

    let activeDuration = 2500;

    let isInteracting = watcher(false, onControlsChange);
    let isSettingsOpen = watcher(false, onControlsChange);
    function onEndInteraction() {
        $isInteracting = false;
    }

    function onStartInteraction(evt) {
        const skipIfFullscreen = evt.type === "mouseenter" && $state.isFullscreen;
        if (skipIfFullscreen) {
            return;
        }

        $isInteracting = true;
    }

    let hideControlsTimeout;

    function hideWithDelay(delay = activeDuration) {
        clearTimeout(hideControlsTimeout);

        hideControlsTimeout = setTimeout(() => {
            $state.isOverlayHidden = true;
        }, delay);
    }

    $: isPaused = $state.playerState !== "playing";
    $: {
        isPaused; // It is here to tell svelte the relation with the watcher
        onControlsChange();
    }

    function onControlsChange() {
        clearTimeout(hideControlsTimeout);

        if ($isInteracting || $isSettingsOpen) {
            $state.isOverlayHidden = false;
            return;
        }

        if (!isPaused) {
            $state.isOverlayHidden = false;
            hideWithDelay();
            return;
        }
        $state.isOverlayHidden = false;
    }

    const fullScreenEvents = [
        "webkitfullscreenchange",
        "mozfullscreenchange",
        "fullscreenchange",
        "MSFullscreenChange",
        "webkitbeginfullscreen",
        "webkitendfullscreen",
    ];
    onMount(() => {
        fullScreenEvents.forEach((event) => {
            $state.playerContainer.addEventListener(event, onFullscreenchange);
        });
    });
    onDestroy(() => {
        fullScreenEvents.forEach((event) => {
            $state.playerContainer.removeEventListener(
                event,
                onFullscreenchange
            );
        });
    });
    function onFullscreenchange() {
        $state.isFullscreen = isFullscreen();
    }

    function togglePlayPause() {
        $state.adapter?.isPaused
            ? $state.adapter?.play()
            : $state.adapter?.pause();
    }

    function closeSettings() {
        if (!$state.isSettingsOpen || isFullscreen()) {
            return;
        }
        $state.isSettingsOpen = false;
    }

    function toggleMute() {
        $state.adapter?.isMuted
            ? $state.adapter?.unmute()
            : $state.adapter?.mute();
    }

    function keyup(event: KeyboardEvent) {
        switch (event.key) {
            case "Esc": // IE/Edge specific value
            case "Escape":
                closeSettings();
                break;
            case "k":
                togglePlayPause();
                break;
            case "f":
                toggleFullscreen($state.playerContainer);
                break;
            case "m":
                toggleMute();
                break;
            case "j":
                $state.adapter?.skipTo(-10);
                break;
            case "l":
                $state.adapter?.skipTo(10);
                break;
            case "i":
                togglePictureInPicture($state.adapter?.video);
                break;
            default:
                break;
        }
    }
</script>

<div
    bind:this={$state.playerContainer}
    tabindex="0"
    class="vch-player"
    on:mouseenter={onStartInteraction}
    on:mouseleave={onEndInteraction}
    on:mousemove={onEndInteraction}
    on:touchstart={onStartInteraction}
    on:touchend={onEndInteraction}
    on:focus={onStartInteraction}
    on:blur={onEndInteraction}
    on:keyup={keyup}
    bind:clientWidth={$state.playerWidth}
    bind:clientHeight={$state.playerHeight}
>
    <slot />

    <div class="skin-container">
        <VchOverlay />
        <VchInfoBox />
        <VchLoaderOverlay />
        <VchClipControls />
        <VchSettingsOverlay />
        <VchSlider />
        <VchControlBar />
    </div>
</div>

<style type="text/scss" lang="scss">
    @import "../globals/mixins.scss";
    .vch-player {
        @include defaultText();

        *,
        *:before,
        *:after {
            box-sizing: border-box;
        }

        display: block;
        position: relative;
        direction: ltr;

        width: 100%;
        height: 100%;
        max-height: 100%;

        min-width: 250px;
        min-height: 100px;

        margin: auto;

        background-color: #000;
    }
</style>
