<script lang="ts">
    import { getContext } from "svelte";
    import { fade } from "svelte/transition";

    import type { Writable } from "svelte/store";
    import type { State } from "../../types/state";

    import VchPlayBtn from "./VchPlayBtn.svelte";
    import VchVolumeCtrl from "./VchVolumeCtrl.svelte";
    import VchTime from "./VchTime.svelte";
    import VchFullscreenBtn from "./VchFullscreenBtn.svelte";
    import VchSettingsBtn from "./VchSettingsBtn.svelte";

    import { overlayTransitionDuration } from "../../utils/constants";

    let state: Writable<State> = getContext("state");

    $: showOverlay = !$state.isOverlayHidden || $state.isSettingsOpen;
</script>

{#if showOverlay}
    <div
        class="controls"
        transition:fade={{ duration: overlayTransitionDuration }}
    >
        <VchPlayBtn />
        <VchVolumeCtrl />
        <div class="spacer" />
        <VchTime />
        <VchSettingsBtn />
        <VchFullscreenBtn />
    </div>
{/if}

<style type="text/scss" lang="scss">
    .controls {
        position: absolute;
        display: flex;
        align-items: flex-end;
        flex-wrap: nowrap;

        width: 100%;
        min-height: calc(2em + 0.2em);

        position: absolute;
        bottom: 0;

        .spacer {
            flex: 1;
        }
    }
</style>
